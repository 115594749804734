import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

const {host} = environment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token: string;

  constructor(
    private http: Http
  ) {
  }

  login(email, password) {
    return new Promise((res, rej) => {
      this.http.post('/admin_api/login', {email, password})
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);
          if (data.data && data.data.token) {
            localStorage.setItem('token', data.data.token);
            console.log("token now ",  data.data.token)
            localStorage.setItem('user', JSON.stringify(data.data.user));
            if (!localStorage.getItem('lang'))
              localStorage.setItem('lang', 'ru');
              setTimeout(function(){res(data.data.role);}, 1000);
            
          } else {
            rej(false);
          }
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  restorePassword(email) {
    return new Promise((res, rej) => {
      this.http.post('/admin_api/restore_password', {email})
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);
          if (data.data) {
            res(true);
          } else {
            rej(false);
          }
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  restorePasswordConfirm(password, token) {
    return new Promise((res, rej) => {
      this.http.post('/admin_api/change_password', {password, token})
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);
          res(data);
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  registrationConfirm(body) {
    return new Promise((res, rej) => {
      this.http.post('/admin_api/change_password', body)
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);
          res(data);
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  updateMe(data) {
    return new Promise((res, rej) => {
      this.http.put('/admin_api/me', data)
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);

          if (data.token) {
            this.token = data.token;
            localStorage.setItem('token', this.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            res(data);
          } else {
            res(data);
          }
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  getMe() {
    return new Promise((res, rej) => {
      const headers = new Headers();
      let params = {};
      const options = new RequestOptions({headers, params});

      this.http.get('/admin_api/me', options)
        .toPromise()
        .then(response => {
          const data = JSON.parse(response['_body']);
          res(data)
        })
        .catch(error => {
          rej(false);
        });
    });
  }

  logout() {
    this.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('users');
  }
}
