import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard, NotAuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', loadChildren: () => import('./admin-body/admin-body.module').then(m => m.AdminBodyModule), canActivate: [AuthGuard] },
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [NotAuthGuard] },
      { path: 'restore', loadChildren: () => import('./restore/restore.module').then(m => m.RestoreModule), canActivate: [NotAuthGuard] },
      { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule), canActivate: [NotAuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
