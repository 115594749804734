import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {customHttpProvider} from './_helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { HttpModule } from '@angular/http';
import {AuthGuard, NotAuthGuard} from './guards/auth.guard';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage'


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyDkG4-Eh7XekIpOKKvGW6MBFii_ua2HXDo",
      authDomain: "test-7698d.firebaseapp.com",
      databaseURL: "https://test-7698d.firebaseio.com",
      projectId: 'test-7698d',
      storageBucket: "test-7698d.appspot.com",
      messagingSenderId: "123654532137",
      appId: "1:123654532137:web:ec24b05ff5ee47604f8038"
    }, 'test2'),

    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule
  ],
  providers: [
    customHttpProvider,
    AuthService,
    AuthGuard,
    NotAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
