import {Injectable} from '@angular/core';
import {
  ConnectionBackend,
  XHRBackend,
  RequestOptions,
  Request,
  RequestOptionsArgs,
  Response,
  Http,
  Headers
} from '@angular/http'
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

const {host} = environment;

@Injectable()
export class CustomHttp extends Http {
  private token;

  constructor(backend: ConnectionBackend, defaultOptions: RequestOptions) {
    super(backend, defaultOptions);
    this.token = localStorage.getItem('token');
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.get(host + url, this.addJwt(options));
  }

  post(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.post(host + url, body, this.addJwt(options));
  }

  put(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.put(host + url, body, this.addJwt(options));
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.delete(host + url, this.addJwt(options));
  }

  private addJwt(options?: RequestOptionsArgs): RequestOptionsArgs {
    options = options || new RequestOptions();
    options.headers = options.headers || new Headers();

    if (this.token) {
      //options.headers.append('x-access-token', this.token);
    }
    return options;
  }
}

export function customHttpFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions): Http {
  return new CustomHttp(xhrBackend, requestOptions);
}

export let customHttpProvider = {
  provide: Http,
  useFactory: customHttpFactory,
  deps: [XHRBackend, RequestOptions]
};
